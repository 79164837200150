<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pic" label="图标" show-overflow-tooltip>
        <template #default="s">
          <img :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border: .5rem" alt="">
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template #default="s">
          <el-button @click="edit(s.row)" type="primary">编辑</el-button>
          <el-button @click="remove(s.row)" type="danger">删除</el-button>
        </template>
        <template #header>
          <el-button @click="editBox = true">添加分类</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="编辑分类" width="30rem" :visible.sync="editBox" @close="editClose">
      <el-form :model="editForm">
        <el-form-item label="分类名称">
          <el-input v-model="editForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <y_upload_img v-model="editForm.pic"></y_upload_img>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import y_upload_img from "@/components/y_upload/y_upload_img";
export default {
  components:{y_upload_img},
  name: "cate",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{
        title:"",
        pic:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$api.addons.knowledge.cate({
        page:this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    remove({id}){
      this.$api.addons.knowledge.cateDel({id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    edit(item){
      this.editForm = {...item}
      this.editBox = true;
    },
    editClose() {
      this.editForm = {
        title:"",
        pic:"",
      }
    },
    editSubmit(){
      this.$api.addons.knowledge.cateEdit(this.editForm).then(()=>{
        this.$message.success("操作成功")
        this.load();
        this.editBox = false;
      })
    },
  },
}
</script>

<style scoped>

</style>